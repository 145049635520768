/**
 * 基础路由
 * @type { *[] }
 */
import Layout from '@/layout'

export const constantRouterMap = [

  {
    path:'/login',
    name:'Login',
    component: () => import('@/views/Login'),
  },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    meta: {
      title: '首页',
      keepAlive: false
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home'),
        meta: { title: '首页', keepAlive: false,shortName:'Home' }
      },
      {
        path: '/page2',
        name: 'page2',
        component: () => import('@/views/home/page2'),
        meta: { title: '首页', keepAlive: false,shortName:'page2' }
      }
    ]
  },

  {
    path: '/platform',
    component: Layout,
    meta:{title:'平台管理',parentName:'home'},
    children: [
      {
        path: '/dataPlatform',
        name: 'DataPlatform',
        component: () => import('@/views/platform/dataPlatform'),
        meta: { title: '数据平台管理', keepAlive: false,shortName:'dataPlatform',parentName:'platform' }
      },
      {
        path: '/operationPlatform',
        name: 'OperationPlatform',
        component: () => import('@/views/platform/operationPlatform'),
        meta: { title: '运营平台管理', keepAlive: false,shortName:'operationPlatform',parentName:'platform' }
      },
    ]
  },

  {
    path: '/dataManage',
    component: Layout,
    meta: { title: '数据管理',parentName:'home'},
    children: [
      {
        path: '/userData',
        name: 'UserData',
        component: () => import('@/views/dataManage/userData'),
        meta: { title: '用户数据', keepAlive:false, shortName:'userData',parentName:'dataManage' },
      },
      {
        path: '/platformData',
        name: 'PlatformData',
        component: () => import('@/views/dataManage/platformData'),
        meta: { title: '平台数据', keepAlive:false, shortName:'platformData',parentName:'dataManage' },
      },
      {
        path: '/mechanismData',
        name: 'MechanismData',
        component: () => import('@/views/dataManage/mechanismData'),
        meta: { title: '机构数据', keepAlive:false, shortName:'mechanismData',parentName:'dataManage' },
      },
    ]
  },


  {
    path: '/resources',
    component: Layout,
    meta: { title: '资源管理',parentName:'home'},
    children: [
      {
        path: '/selfSupport',
        name: 'SelfSupport',
        component: () => import('@/views/resources/selfSupport'),
        meta: { title: '自营商品库', keepAlive:false, shortName:'selfSupport',parentName:'resources', },
      },
      {
        path: '/purchase',
        name: 'Purchase',
        component: () => import('@/views/resources/purchase'),
        meta: { title: '采购订单', keepAlive:false, shortName:'purchase',parentName:'resources', },
      },
    ]
  },

  {
    path: '/mechanism',
    component: Layout,
    meta: { title: '机构管理',parentName:'home'},
    children: [
      {
        path: '/settleIn',
        name: 'SettleIn',
        component: () => import('@/views/mechanism/settleIn'),
        meta: { title: '机构入驻审核', keepAlive:false, shortName:'settleIn',parentName:'mechanism', },
      },
      {
        path: '/goodsOnShelves',
        name: 'goodsOnShelves',
        component: () => import('@/views/mechanism/goodsOnShelves'),
        meta: { title: '商品上下架审核', keepAlive:false, shortName:'goodsOnShelves',parentName:'mechanism', },
      },
      {
        path: '/mechanismOnShelves',
        name: 'MechanismOnShelves',
        component: () => import('@/views/mechanism/mechanismOnShelves'),
        meta: { title: '机构入驻审核', keepAlive:false, shortName:'mechanismOnShelves',parentName:'mechanism', },
      },
    ]
  },
];
