<template>
    <div class="sideBar">
      <div class="orange-btn whitefont index" @click="toHome()">
        <i class="iconfont icon-a-zu30"/>
        <span>首页</span>
      </div>
      <div class="side-item-block">
        <el-menu
          :default-active="defaultActive"
          :unique-opened="true"
          class="el-menu-vertical-demo"
          text-color="#595959"
          @open="handleOpen"
          @close="handleClose">
          <template v-for="item in sidebarItem">
            <el-submenu :index="item.shortName" v-if="item.children">
              <template slot="title">
                <i class="iconfont" :class="item.icon"/>
                <span>{{item.title}}</span>
              </template>
              <el-menu-item style="padding-left: 29px" v-for="sub in item.children" :index="sub.shortName" @click="handleMenuItem(sub)">{{sub.title}}</el-menu-item>
            </el-submenu>

            <el-menu-item class="el-menu" :index="item.shortName" @click="handleMenuItem(item)" v-else>
              <template slot="title">
                <i class="iconfont" :class="item.icon"/>
                <span>{{item.title}}</span>
              </template>
            </el-menu-item >
          </template>

        </el-menu>
      </div>
    </div>
</template>

<script>
  let vm;
  export default {
    name: 'sideBar',
    data(){
      return{
        sidebarItem:[
          {
            title:'数据看板',
            shortName:'platform',
            parentName:'home',
            icon:'icon-a-zu31',
            children:[
              {
                title:'数据平台管理',
                shortName:'dataPlatform',
                name:"DataPlatform",
                parentName:'platform',
              },
              {
                title:'运营平台管理',
                shortName:'operationPlatform',
                name:"OperationPlatform",
                parentName:'platform',
              }
            ]
          },
          {
            title:'账号管理',
            shortName:'dataManage',
            parentName:"home",
            icon:'icon-a-ziyuan2',
            children:[
              {
                title:'用户数据',
                shortName:'userData',
                name:'UserData',
                parentName:'dataManage',
              },
              {
                title: '平台数据',
                shortName: 'platformData',
                name: 'PlatformData',
                parentName:'dataManage',
              },
              {
                title: '机构数据',
                shortName: 'mechanismData',
                name: 'MechanismData',
                parentName:'dataManage',
              },
            ]
          },
        ],
        defaultActive: this.$route.meta.parentName,
      }
    },
    created() {
      // console.log(this.sidebarItem)
    },
    computed: {
      sidebar() {
        return this.$store.state.user.sidebarItem;
      },

      active(){
        return this.$route.meta.parentName;
      },
    },
    watch:{
      sidebar(val, old) {
        // vm.sidebarItem = val;
        vm.$store.commit('user/SET_SIDEBAR', vm.sidebarItem);
        vm.$forceUpdate();
      },

      active(val, old) {
        vm.defaultActive = val;
      }
    },
    mounted() {
      vm = this;
      // vm.init();
    },
    methods: {
      handleOpen(key, keyPath) {

      },
      handleClose(key, keyPath) {

      },

      handleMenuItem(obj){
        vm.$router.push(obj.shortName);
        vm.$store.commit('tagsView/pushtags',obj)

      },

      init(){
        if (localStorage.getItem('village_sidebar')) {
          vm.sidebarItem = JSON.parse(localStorage.getItem('village_sidebar'))
        }
        vm.$store.commit('user/SET_SIDEBAR',vm.sidebarItem)
      },

      toHome(){
        vm.$router.push({name:'Home'})
        let obj = {
          name: "Home",
          shortName: "Home",
          title: "首页",
        }
        vm.$store.commit('tagsView/pushtags',obj)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../styles/sidebar";

  /deep/.el-menu {
    padding-left: 18px !important;
  }
</style>


