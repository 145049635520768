const api = {

  userinfo:'/user/detail' ,  //我的信息

  logout: '/villageparent/user/login-out', //退出登录

  // login:'/auth/login',  //登录
  login:'/villageparent/login',  //登录

  material:'/user/material',    //修改密码

  member:'/member',   //用户列表

  detail:'/member/show',  //用户详情

  apply:'/member/apply',  //身份审核

  bind:"/member/bind",  //绑定身份

  sq_list:'/area',    //社区列表

  del_area:'/area/delete',  //删除小区

  edit_area:'/area/update',   //编辑小区

  node:'/node',  //菜单（节点）列表

  del_node:"/node/delete",    //删除节点

  update_node:'/node/update',   //修改节点

  dzz_list:"/ccp/branch",   //党组织列表

  del_branch:"/ccp/branch/delete",    //删除党支部

  update_branch:"/ccp/branch/update",   //修改党支部

  party:'/ccp/party',   //党员列表

  party_show:'/ccp/party/show',   //党员详情

  update_party:'/ccp/party/update',   //修改党员

  del_party:'/ccp/party/delete',   //删除党员

  import_party:'/ccp/import',   //导入党员

  ad_list:'/ad',    //广告列表

  del_ad:'/ad/delete',  //删除广告

  update_ad:"/ad/update",   //修改广告

  upload:'/common/upload',    //上传附件

  task:'/task',   //任务列表

  task_show:'/task/show',   //任务详情

  task_confirm:'/task/confirm',   //确认活动完结

  update_task:'/task/update',   //修改任务

  del_task:'/task/delete',    //删除任务

  cancel_task:'/task/cancel',   //取消任务

  join:'/task/join',    //参与人员

  sign:'/task/sign',    //报名

  content:'/content',   //内容管理

  del_content:'/content/delete',  //删除内容

  update_content:'/content/update',  //删除内容

  stream:"/stream",   //积分流水

  account:'/account',   //账号列表

  del_account:'/account/delete',   //账号列表

  update_account:'/account/update',   //编辑账号

  role:'/role',   //角色

  del_role:'/role/delete',    //删除角色

  update_role:'/role/update',   //修改角色

  organ:'/organ',   //机构列表

  del_organ:'/organ/delete',  //删除机构

  organ_show:'/organ/show', //机构详情

  organuser:'/organuser',   //机构用户列表

  organuser_show:'/organuser/show',   //机构用户详情

  del_organuser:'/organuser/delete',    //删除机构用户

  bind_organ:'/organuser',    //绑定机构

  menu:'/menu',   //节点列表

  permission:'/permission',  //权限列表

  organ_list:'/organ/list',   //机构列表

  villages:'/villages',   //社区登录图片

  products:'/products', //商品列表

  orders:'/orders',//订单列表

  platformData: '/villageparent/census/detail',//首页数据


  scoreChangeTotal: '/villageparent/census/score-change-total', //积分变动数量统计
  scoreEvent: '/villageparent/census/score-event', //积分事件数量统计
  vUsers: '/villageparent/census/v-users', //辖区人数分布-首页

  platformDataShow: '/villageparent/census/village-detail',//详情数据
  roleRank: '/villageparent/census/village-role-rank', //辖区积分排行
  residRank: '/villageparent/census/resid-rank', // 各村组小区排名
};
export default api
