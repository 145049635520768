<template>
<!--  <v-scale-screen width="1920" height="1080">-->
    <div id="app">
        <router-view />
    </div>
<!--  </v-scale-screen>-->
</template>
<script>
  import VScaleScreen from 'v-scale-screen'
export default {
  name: 'App',
  components:{
    VScaleScreen
  }
}
</script>
<style lang="scss">
  @import "styles/variables";
  [v-cloak]{
    display: none;
  }
  /*#app{*/
  /*  width:1920px;*/
  /*  height: 1080px;*/
  /*}*/
  .index-container{
    background-color: #FFFFFF;
    border-radius: 12px;
    font-size: 14px;
    *{
      box-sizing: border-box;
    }
  }
  .el-date-picker.yingye-long-date{
    .el-date-picker__editor-wrap{
      &:last-child{
        display: none;
      }
    }
  }
  .el-date-range-picker.yingyeDate{
    .el-date-range-picker__time-picker-wrap{
      width: 66%;
      &:last-child{
        display: none;
      }

    }
  }
</style>

