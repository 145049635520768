<template>
  <div class="navbar">
    <div class="left">
      <div class="logo">
        <img src="../../assets/images/logo.png" alt="" />
      </div>
      <span class="f16 blackfont name">
        {{ '运营中心 - - ' + sheqv_address }}
      </span>
    </div>

    <div class="tool">
      <el-input size="mini" placeholder="查询用户、活动、资产"></el-input>
      <i class="el-icon-message"></i>
      <span v-if="!login" style="width: 157px">
        <span class="mr16 ver-middle"><router-link :to="{ path: '/login' }">登录</router-link></span>
      </span>
      <el-popover placement="bottom" v-if="login" width="157" trigger="click">
        <span v-if="login" class="curpor" style="display: inline-block " slot="reference">
          <el-avatar size="small" :src="userInfo.avatar_url || circleUrl" />
          <span class="f14 grayfont-65 " style="vertical-align: middle">设置</span>
        </span>
        <div class="popover-block">
          <div>
            <div>{{ userInfo.realname }}</div>
            <div class="grayfont-32 mt10">账号：{{ userInfo.username }}</div>
          </div>
          <ul>
            <!--<li @click="updatePassword()"><i class="iconfont icon-mimasuo" /> <span>修改密码</span></li>-->
            <!--            <li >-->
            <!--              <i class="iconfont icon-shezhi"></i> <span>账号设置</span>-->
            <!--            </li>-->
            <li @click="loginOut()"><i class="iconfont icon-tuichu" /> <span>退出登录</span></li>
          </ul>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { getToken, removeToken } from '../../utils/auth'

export default {
  data() {
    return {
      circleUrl: require('../../assets/images/default-avatar.png'),
      userInfo: {},
      sheqv_address: '',
      login: false
    }
  },
  computed: {
    monitor() {
      return this.$store.state.user.userInfo
    }
  },
  watch: {
    monitor() {
      this.userInfo = this.$store.state.user.userInfo
      this.$forceUpdate()
    }
  },
  mounted() {
    this.getUserInfo()
    if (!getToken()) {
      this.login = false
      this.$router.push({ name: 'Login' })
    } else {
      this.login = true
    }
  },
  methods: {
    getUserInfo() {
      if (localStorage.getItem('userinfo')) {
        this.userInfo = JSON.parse(localStorage.getItem('userinfo'))
        // console.log("这是")
        console.log(this.userInfo)
        // this.sheqv_address = this.userInfo.villages.full
      }
    },

    loginOut() {
      removeToken()
      this.$store.commit('user/DEL_USERINFO')
      this.$store.commit('user/DEL_SIDEBAR')
      this.$store.commit('tagsView/delete_tagsView')
      this.$router.push('/login')
    },

    updatePassword() {
      this.$router.push({ name: 'UpdatePassword' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.navbar {
  width: 100%;
  height: 48px;
  background-color: rgb(245, 164, 75);
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.12);

  .left {
    display: flex;
    align-items: center;
    // justify-content: space-between;

    .logo {
      width: 110px;
      height: 42px;
      background-color: #ffffff;
      border-radius: 5px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 79px;
        height: 32px;
        object-fit: cover;
        // margin-right: 15px;
        vertical-align: middle;
      }
    }

    .name {
      font-weight: bold;
      color: #ffffff;
      font-size: 17px;
      border-left: 1px solid #ffffff;
      padding-left: 15px;
      vertical-align: middle;
      display: inline-block;
    }
  }

  .iconfont {
    color: #595959;
    font-size: 16px;
    margin-right: 26px;
    vertical-align: middle;
  }
  .avtar {
  }
}

.tool {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-input {
    width: 160px;
    border-radius: 5px;
  }

  .popover-block {
    ul {
      border-top: 1px solid $line;
      margin-top: 12px;
      padding: 10px 0 0;
      li {
        padding: 7px 5px;
        cursor: pointer;
        .iconfont {
          font-size: 14px;
          color: #acacac;
          margin-right: 12px;
        }
      }
    }
  }
}

/deep/.el-avatar--small {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
}
/deep/.el-popover {
  padding: 16px !important;
}
</style>
